import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";

import SnackbarUtils from "../../../notistack";
import { Select } from "../../../../_metronic/_partials/controls";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export default function TestTypeDropdown({
    randomQuestions,
    onChange,
    onAddNewItem,
    onEditItem,
    item,
    setItem
}) {
    const classes = useStyles();
    return (
        <div>
            <div
                style={{ border: "1px solid rgb(230 230 230)", borderRadius: "3px", marginBottom: '12px' }}
            >
                <FormControl className={classes.formControl}>

                    <Select
                        label="Mock Type"
                        native
                        name="type"
                        defaultValue=""
                        value={item.mocks_type}
                        onChange={(e) => {
                            const { value } = e.target;
                            setItem((prev) => ({ ...prev, mocks_type: value }));

                        }}
                    >
                        <option aria-label="None" value="" />
                        <option value={"full_test"}>Full Test</option>
                        <option value={"section_test"}>Section Test</option>
                        {/* <option value={"question_test"}>Question Test</option> */}
                    </Select>
                </FormControl>
                {item.mocks_type === "section_test" || item.mocks_type === "question_test" ? <FormControl className={classes.formControl}>
                    <Select
                        label="Section Test"
                        defaultValue=""
                        native
                        name="Section Test"
                        value={item.is_section || ""}
                        onChange={(e) => {
                            const { value } = e.target;
                            setItem((prev) => ({ ...prev, is_section: value }));
                        }}
                    >
                        {/* <option aria-label="None" value="" /> */}
                        <option value={'speaking'}>Speaking</option>
                        <option value={'writing'}>Writing</option>
                        <option value={'reading'}>Reading</option>
                        <option value={'listening'}>Listening</option>
                    </Select>
                </FormControl> : null}
                {item.mocks_type === "question_test" ?
                    <FormControl className={classes.formControl}>
                        <Select
                            label="Question Type"
                            defaultValue=""
                            native
                            name="Question Test"
                            value={item.is_question || ""}
                            onChange={(e) => {
                                const { value } = e.target;
                                setItem((prev) => ({ ...prev, is_question: value }));
                            }}
                        >
                            {item.is_section === "speaking" && <option value="read_aloud">Read Aloud</option>}
                            {item.is_section === "speaking" && <option value="repeat_sentence">Repeat Sentence</option>}
                            {item.is_section === "speaking" && <option value="describe_image">Describe Image</option>}
                            {item.is_section === "speaking" && <option value="retell_lecture">Retell Lecture</option>}
                            {item.is_section === "speaking" && <option value="answer_short_question">Answer Short Question</option>}

                            {item.is_section === "writing" && <option value="summarize_written_text">Summarize Written Text</option>}
                            {item.is_section === "writing" && <option value="write_essay">Write Essay</option>}

                            {item.is_section === "reading" && <option value="single_answer">Multiple Choice(Single)</option>}
                            {item.is_section === "reading" && <option value="multiple_answers">Multiple Choice(Multiple)</option>}
                            {item.is_section === "reading" && <option value="reorder_paragraph">Reorder Paragraph</option>}
                            {item.is_section === "reading" && <option value="fill_in_blanks">R-Fill In The Blanks</option>}
                            {item.is_section === "reading" && <option value="writing_fill_in_blanks">RW-Fill In The Blanks</option>}

                            {item.is_section === "listening" && <option value="summarize_spoken_text">Summarize Spoken Text</option>}
                            {item.is_section === "listening" && <option value="LMCQCSA">Multiple Choice(single)</option>}
                            {item.is_section === "listening" && <option value="LMCQCMA">Multiple Choice(multiple)</option>}
                            {item.is_section === "listening" && <option value="fill_in_the_blanks">Fill In The Blanks</option>}
                            {item.is_section === "listening" && <option value="highlight_correct_summary">Highlight Correct Summary</option>}
                            {item.is_section === "listening" && <option value="select_missing_word">Select Missing Word</option>}
                            {item.is_section === "listening" && <option value="highlight_incorrect_words">Highlight Incorrect Words</option>}
                            {item.is_section === "listening" && <option value="write_from_dictation">Write From Dictation</option>}

                        </Select>
                    </FormControl> : null}
            </div>

        </div>
    );
}
