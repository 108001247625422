import React from "react";

const WritingUserAnswer = ({ correction }) => {
  return (
    <>
      {correction?.answer}

    </>
  );
};

export default WritingUserAnswer;
