import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import {ModalProgressBar} from "../../../../_metronic/_partials/controls";

export function CorrectionEditDialogHeader({ id }) {
  // corrections Redux state
  const { correctionForEdit, actionsLoading } = useSelector(
    (state) => ({
      correctionForEdit: state.corrections.correctionForEdit,
      actionsLoading: state.corrections.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  // Title couting
  useEffect(() => {
    let _title = id ? "" : "New correction";
    if (correctionForEdit && id) {
      _title = `correction id #${correctionForEdit.id}`;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [correctionForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
